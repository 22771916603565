<template></template>
<script>
export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$router.replace({
        name: 'RepairProcessDetails',
        params: { index: vm.$route.params.index },
      })
      // 跳到该路由页面后，再替换为from.path来源路径
    })
  },
}
</script>
